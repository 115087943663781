.#{$search-prefix}-rescue {
  $self: &;

  display: flex;
  flex-direction: column;
  padding: 18px 13px 32px;
  font-weight: $font-weight-regular;
  background-color: $andes-white;

  &__list {
    margin: 25px 10px 10px 0;
  }

  &__item {
    line-height: 1.231;
    color: $andes-gray-900;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    list-style-type: disc;
    list-style-position: outside;
    margin-bottom: 3px;
    margin-left: 25px;
  }

  &__warning {
    .#{$search-prefix}-warning__message {
      padding-right: 0;
    }
  }

  &__label {
    font-size: $font-size-14;
    margin: 10px 0 30px;
    color: $andes-gray-900;
  }

  &__button {
    margin: 16px 0;
  }

  &__link--decline {
    display: flex;
    justify-content: center;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }

  &--adults {
    padding-bottom: 32px;

    #{$self}__list {
      margin: 14px 5px 24px 0;
    }

    #{$self}__item {
      margin: 0;
      list-style: none;

      a {
        margin-left: 4px;
      }
    }

    #{$self}__button {
      margin-bottom: 19px;
    }

    .#{$search-prefix}-warning__message {
      padding-left: 8px;
    }

    .#{$search-prefix}-icon--warning {
      transform: translate(3px, 0);
    }
  }

  &--eshop_zrp {
    padding-bottom: 18px;

    #{$self}__link--decline {
      margin-top: 16px;
    }
  }
}
