@import './icons';

.#{$search-prefix}-icon {
  &--chevron {
    @include size(9px, 14px);

    stroke: $andes-blue-500;
  }

  &--warning {
    @include size(70px, 51px);

    fill: $search-color-warning;
  }

  &--search {
    @include size(14px, 14px);

    fill: $andes-gray-550;
  }

  &--full {
    fill: $andes-success-green;
  }

  &--loyalty {
    @include size(16px, auto);

    fill: $search-green-150;
  }

  &--alert-new,
  &--alert-edit {
    @include size(22px, auto);

    fill: $andes-blue-500;
  }

  &--disclaimer {
    &__container {
      @include size(16px, 16px);

      background-color: $andes-blue-500;
      text-align: center;
      border-radius: 100%;
    }

    &__content {
      @include size(8px, 8px);

      fill: $andes-white;
      position: relative;
      bottom: $andes-spacing-4;
    }
  }

  &--close {
    @include size(12px, 12px);
  }

  &--rest {
    @include size(36px, 34px);

    fill: $search-dodger-blue;
  }

  &--sum {
    @include size(36px, 34px);

    fill: $search-dodger-blue;
  }

  &--meli {
    margin-right: 4.5px;
  }

  &--supermarket-logo {
    width: 100px;
    height: 15px;
  }

  &--international-logo {
    @include size(189px, 39px);
  }

  &--international-filter-cbt-mobile {
    margin-bottom: -5px;
  }

  &--meliplus {
    margin-left: 3px;
  }

  &--blue-check {
    padding: 0 $andes-spacing-4;
  }

  &--star-full {
    fill: transparent;
    stroke: $andes-blue-500;
  }

  &--eshop-image {
    border: 1px solid $andes-gray-070;
    border-radius: $border-radius-6;
    z-index: 2;
  }

  &--cockade {
    margin-left: $andes-spacing-4;
  }
}
