.#{$search-prefix}-warning {
  &__message {
    display: flex;
    background: $andes-white;
    color: $andes-gray-550;
    line-height: 1.2em;
    padding: 16px 12px 5px 13px;
    border: 1px solid $andes-gray-100;
    border-radius: 3px;
  }

  &__label {
    font-size: $font-size-16;
    margin-left: 13px;
    margin-top: 2px;
    font-weight: $font-weight-regular;
  }
}
