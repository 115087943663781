@import '../../../styles/variables';
@import '../../../styles/common';
@import '~@andes/button/index';
@import '../../../components-v2/icons/styles/mobile';
@import '../../../components/warning/styles/warning.mobile';
@import '../../../components/rescue/styles/rescue.mobile';

body {
  background-color: $search-bg-color;
}

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 0;
}
